import React from 'react';
import './Footer.css';

function Footer() {
  return (
    <footer className="footer">
      <div className="footer-section">
        <h4>Contato</h4>
        <p>+55 11 1234-5678</p>
        <p>email@nubo.com.br</p>
      </div>
      <div className="footer-links">
        <a href="#products">Produtos</a>
        <a href="#plans">Planos</a>
        <a href="#resources">Recursos</a>
        <a href="#help">Ajuda</a>
      </div>
      <p>&copy; 2024 Nubo Rocks. Todos os direitos reservados.</p>
    </footer>
  );
}

export default Footer;
