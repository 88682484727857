import React from 'react';
import Navbar from './components/Navbar/';
import HeroSection from './components/HeroSection/';
import FeatureCards from './components/FeatureCards/';
import ProductShowcase from './components/ProductShowcase/';
import Testimonials from './components/Testimonials/';
import Footer from './components/Footer/';
import './index.css';

function App() {
  return (
    <div className="App">
      <Navbar />
      <HeroSection />
      <FeatureCards />
      <ProductShowcase />
      <Testimonials />
      <Footer />
    </div>
  );
}

export default App;
