import React from 'react';
import './Navbar.css';
import logo from '../../assets/logo.png';

function Navbar() {
  return (
    <nav className="navbar">
      <img src={logo} alt="Nubo Rocks Logo" className="navbar-logo" />
      <ul className="navbar-links">
        <li>Produtos</li>
        <li>Planos</li>
        <li>Recursos</li>
        <li>Ajuda</li>
        <li>Contato</li>
      </ul>
      <button onClick={() => window.open('https://app.nubo.rocks', '_blank')} className="navbar-cta">Teste Grátis</button>
    </nav>
  );
}

export default Navbar;
