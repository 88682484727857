import React from 'react';
import './ProductShowcase.css';

function ProductShowcase() {
  const products = [
    { name: 'Nubo Rocks CRM', description: 'Gerencie seu funil de vendas e melhore suas conversões.' },
    { name: 'Nubo Rocks Marketing', description: 'Automatize campanhas e acompanhe o engajamento.' },
    { name: 'Nubo Rocks Conversas', description: 'Converse com seus leads em tempo real.' },
  ];

  return (
    <section className="product-showcase">
      <h2>Conheça os produtos Nubo Rocks</h2>
      <div className="product-cards">
        {products.map((product, index) => (
          <div className="product-card" key={index}>
            <h3>{product.name}</h3>
            <p>{product.description}</p>
          </div>
        ))}
      </div>
    </section>
  );
}

export default ProductShowcase;
