import React from 'react';
import './FeatureCards.css';

function FeatureCards() {
  const features = [
    { title: 'Campanhas de Email', description: 'Automatize suas campanhas e envie emails personalizados.' },
    { title: 'Automação Inteligente', description: 'Segmente e alcance os clientes ideais no momento certo.' },
    { title: 'Integração Completa', description: 'Conecte-se com suas ferramentas favoritas.' },
    { title: 'Redes Sociais', description: 'Gerencie suas redes sociais diretamente Nubo Rocks.' }
  ];

  return (
    <section className="feature-cards">
      {features.map((feature, index) => (
        <div className="feature-card" key={index}>
          <h3>{feature.title}</h3>
          <p>{feature.description}</p>
        </div>
      ))}
    </section>
  );
}

export default FeatureCards;
