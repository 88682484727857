import React from 'react';
import './HeroSection.css';

function HeroSection() {
  return (
    <section className="hero-section">
      <h1 className="hero-headline">Gere mais leads para seu negócio com Nubo Rocks</h1>
      <p className="hero-subheadline">A ferramenta de automação de marketing ideal para o crescimento de sua empresa.</p>
      <form className="hero-form">
        <input type="email" placeholder="Seu email de trabalho" className="hero-input" />
        <button onClick={() => window.open('https://app.nubo.rocks', '_blank')} type="submit" className="hero-cta-button">Teste Grátis</button>
      </form>
    </section>
  );
}

export default HeroSection;
