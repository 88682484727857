import React from 'react';
import './Testimonials.css';

function Testimonials() {
  const testimonials = [
    { name: 'Carlos Oliveira', comment: 'O Nubo Rocks aumentou nossas conversões em 30%!' },
    { name: 'Fernanda Souza', comment: 'Ferramenta incrível para automação de marketing.' }
  ];

  return (
    <section className="testimonials">
      <h2>Quem usa, aprova e comprova resultados</h2>
      <div className="testimonial-cards">
        {testimonials.map((testimonial, index) => (
          <div className="testimonial-card" key={index}>
            <p>"{testimonial.comment}"</p>
            <p>- {testimonial.name}</p>
          </div>
        ))}
      </div>
    </section>
  );
}

export default Testimonials;
